.topnav {
  padding-left: 0;
  /* height: 4.2rem; */
  z-index: 1039;
  font-size: 0.9rem;
}

.topnav.custom-bg img.logoImage {
  height: 45px !important;
  transition: all linear 0.5s;
}
img.logoImage {
  height: 60px !important;
  transition: all linear 0.5s;
}

.topnav .navbar-brand {
  width: 100%;
  /* //width: 10rem; */
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}
.topnav .navbar-brand img {
  height: 0.8rem;
}
@media (min-width: 992px) {
  .topnav .navbar-brand {
    width: 100%;
    /* width: 10rem; */
  }
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%) !important;
}
.navbar-expand {
  flex-wrap: nowrap;
  /* justify-content: flex-start; */
}
.navbar {
  /* position: relative; */
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  /* justify-content: space-between; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-fixed .topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #008e00;
}
.custom-bg {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0 / 65%) !important;
}

.responsive-text {
  font-size: 1rem; /* Default (Desktop) */
}

@media (max-width: 767px) {
  .responsive-text {
    font-size: 1.3rem; /* Bigger on mobile */
  }
}
