.mt-n5 {
  margin-top: -2.5rem !important;
}

.stickyHeader {
  position: fixed;
  top: 72px;
  /* width: 82%; */
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border: white;
  margin-right: 18px;
  animation: slideDown 0.3s ease forwards;
}

.hidestickyHeader {
  transition: all 0.3s ease;
  animation: slideUp 0.3s ease forwards;
}
/* .stickyHeader + .content {
  padding-top: 102px;
} */

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

.card-zoom-effect {
  transform: scale(1); /* Slight zoom effect */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
}

.card-zoom-effect:hover {
  transform: scale(1.04); /* Slightly more zoom on hover */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
  border: 2px solid green;
}

/* Fade-in animation when modal opens */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-out animation when modal closes */
@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

/* Apply fade-in animation when modal is opened */
.modal.fade.show .modal-dialog {
  animation: modalFadeIn 0.5s forwards;
}

/* Apply fade-out animation when modal is closing */
.modal.fade .modal-dialog {
  animation: modalFadeOut 0.5s forwards;
}
