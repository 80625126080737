.custom-progress-bar-home {
  width: 20% !important;
}

.box {
  display: flex;
  position: relative;
  border: 1px solid #c7c2c27d;
  padding: 10px;
  border-radius: 0px 100px 100px 0px;
  background-color: white;
  height: 155px;
  /* border-bottom-style: dashed; */
  /* border-bottom-width: 3px; */
}

.box1 {
  left: -35px;
  top: -35px;
  position: absolute;
  border: 2px solid #c7c2c27d;
  padding: 5px;
  border-radius: 2px;
  border-style: dashed;
}
.vertical-line {
  position: absolute;
  bottom: 0;
  left: 30px;
  width: 330px;
  top: 144px;
  border-bottom: 2px solid #c7c2c27d;
  border-bottom-style: dashed;
  background-color: white;
  /* height: 11px; */
}

.icon {
  font-size: 65px !important;
}
.desc-class {
  margin-left: 3rem;
  margin-right: 1rem;
  font-size: 18px !important;
}
.CircularProgressbar-text {
  font-weight: bold;
}
.titleFontSize {
  font-size: 6vh !important;
}
@media (max-width: 1400px) {
  .desc-class {
    font-size: 16px !important;
  }
}

@media (max-width: 535px) {
  .box {
    display: flex;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    height: 145px;
  }

  .box1 {
    left: -30px;
    top: -30px;
    padding: 5px;
    border-radius: 2px;
  }
  .vertical-line {
    bottom: 0;
    left: 30px;
    width: 238px;
    top: 135px;
  }
  .icon {
    font-size: 55px !important;
  }
  .desc-class {
    margin-left: 2rem;
    margin-right: 1rem;
    width: 85% !important;
  }
  .titleFontSize {
    font-size: 5vh !important;
  }
}

@media (max-width: 425px) {
  .box {
    display: flex;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    height: 155px;
  }

  .box1 {
    left: -30px;
    top: -30px;
    padding: 5px;
    border-radius: 2px;
  }
  .vertical-line {
    bottom: 0;
    left: 30px;
    width: 210px;
    top: 146px;
  }
  .icon {
    font-size: 45px !important;
  }
  .desc-class {
    margin-left: 1.5rem;
    margin-right: 0.8rem;
  }
}

@media (max-width: 420px) {
  .box {
    display: flex;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    height: 160px;
  }

  .box1 {
    left: -30px;
    top: -30px;
    padding: 5px;
    border-radius: 2px;
  }
  .vertical-line {
    bottom: 0;
    left: 30px;
    width: 180px;
    top: 150px;
  }
  .icon {
    font-size: 45px !important;
  }
  .desc-class {
    margin-left: 1.2rem;
    margin-right: 0.5rem;
  }
  .titleFontSize {
    font-size: 4vh !important;
  }
}
@media (max-width: 365px) {
  .box {
    display: flex;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    height: 160px;
  }

  .box1 {
    left: -30px;
    top: -30px;
    padding: 5px;
    border-radius: 2px;
  }
  .vertical-line {
    bottom: 0;
    left: 30px;
    width: 145px;
    top: 150px;
  }
  .icon {
    font-size: 45px !important;
  }
  .desc-class {
    margin-left: 1.2rem;
    margin-right: 0.5rem;
  }
}

@media (max-width: 352px) {
  .box {
    display: flex;
    padding: 10px;
    border-radius: 0px 100px 100px 0px;
    height: 210px;
  }

  .box1 {
    left: -30px;
    top: -30px;
    padding: 5px;
    border-radius: 2px;
  }
  .vertical-line {
    display: none;
    bottom: 0;
    left: 30px;
    width: 125px;
    top: 161px;
  }
  .icon {
    font-size: 45px !important;
  }
  .desc-class {
    margin-left: 1.2rem;
    margin-right: 0.5rem;
    font-size: 15px;
  }
}
