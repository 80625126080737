header {
  position: relative;
  background-color: black;
  height: 73vh;
  /* height: 100vh; */
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.home-customAbsoluteStyle {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Default order (Desktop view) */
.home-reverse-columns > div:first-child {
  order: 0; /* First column */
}

.home-reverse-columns > div:last-child {
  order: 1; /* Second column */
}
h1 {
  font-size: 6vh; /* On larger screens */
}

@media screen and (max-width: 768px) {
  /* Adjust breakpoint as needed */
  header {
    height: 60vh;
  }
  .home-reverse-columns > div:first-child {
    order: 1; /* First column becomes second */
  }

  .home-reverse-columns > div:last-child {
    order: 0; /* Second column becomes first */
  }

  h1 {
    font-size: 2rem; /* Matches the default <h1> size */
  }
}

header .container {
  position: relative;
  z-index: 2;
}
.custom-img {
  height: 40vh;
}
@media (max-width: 768px) {
  .custom-img {
    height: 30vh;
  }
}
@media (max-width: 480px) {
  .custom-img {
    height: 25vh;
  }
}
header .custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(0 0 0 / 15%);
  z-index: 1;
}

header .custom-faq-overlay {
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.348);
  z-index: 1;
}

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

@media (pointer: coarse) and (hover: none) {
  header {
    background: url("https://source.unsplash.com/XT5OInaElMw/1600x900") black
      no-repeat center center scroll;
  }

  header video {
    display: none;
  }
}
.textContainer {
  padding: 6rem !important;
}
@media (max-width: 1024px) {
  .textContainer {
    padding: 2rem !important;
  }
}
.custom-bg {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0 / 65%);
}
.primary-green {
  color: #008e00;
}
.custom-Link {
  background: #008e001c;
  --bs-text-opacity: 1;
  color: #008e00 !important;
  font-weight: 500;
  text-decoration: none;
  padding: 0px 6px 2px;
  border-radius: 4px;
}

a {
  --bs-text-opacity: 1;
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: #008e00;
}

.custom-scroll {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* hide scrollbar for chrome, safari and opera */
.custom-scroll::-webkit-scrollbar {
  display: none;
}
.question {
  font-family: fairplex-wide, serif;
  font-size: 125%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table-billing-history th,
.table-billing-history td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.topnav ul li a:hover div {
  /* //background: #008e00 !important; */
  padding: 2px 0;
  border-radius: 5px;
  border-bottom: 2px solid #fff;
}
.custom-required:after {
  margin-left: 1px;
  content: "*";
  color: red;
}
.bg-success {
  background-color: #008e00 !important;
}
.custom-card {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.customPaddingfaq {
  padding: 3rem !important;
}
@media (max-width: 767px) {
  .customPaddingfaq {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  border-radius: 30%;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-front {
  background-color: white;
  color: black;
}
.flip-card-back {
  background-color: #008e00;
  color: white;
  transform: rotateY(180deg);
}

.dialog_box {
  position: relative;
  /* padding: 8px; */
  background: #f4f4f4;
  /* border: 1px solid #1178ed; */
}
.dialog_box:after,
.dialog_box:before {
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
}
.dialog_box:after {
  border-width: 27px; /* Increase the size of the arrow */
}
.dialog_box:before {
  border-width: 29px; /* Increase the size of the arrow */
} /* Arrow pointing BOTTOM */
.dialog_box.bottom:after,
.dialog_box.bottom:before {
  left: 46%;
  top: 100%;
}
.dialog_box.bottom:after {
  border-color: #fff transparent transparent transparent;
  margin-left: -14px;
}
.dialog_box.bottom:before {
  /* border-color: #1178ed transparent transparent transparent; */
  margin-left: -16px;
}

.trapezoid {
  width: 100vh;
  clip-path: polygon(0 0, 0 0%, 0% 100%, 87% 100%, 100% 0);
  border-radius: unset;
}

.trapezoid-col {
  height: 20vh;
  width: 100vh;
  clip-path: polygon(0 0, 0 0%, 0% 100%, 94% 100%, 100% 0);
  border-radius: unset;
}
